<script>
export default {
	props: {
		color: {
			required: false,
			type: String,
			default() {
				return "elektra-red";
			},
		},
		fontSize: {
			required: false,
			type: String,
			default() {
				return "text-4xl";
			},
		},
	},
};
</script>

<template>
	<div class="text-center">
		<font-awesome-icon
			icon="fa-solid fa-spinner"
			class="fa-spin"
			:class="[fontSize, color]"
		/>
	</div>
</template>

<style lang="scss" scoped></style>
